/* Pen by muhammed saifudeen*/
/*pen name : saif*/
/* Global */
html, body{
  height: 100%;
}

body{
  background: #d9d1fd;
}

/* common */
.input-wrapper,
.input-box{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}



.input-box,
.input-box input[type='text']{
  border-radius: 80px;
}

/* parent */
.input-wrapper{
  position:relative;
  max-width: 100%;
  height: 100vh;
  margin:0 auto;
}

/* elements */
.input-box{
  position: relative;
  max-width: 350px;
  justify-content: flex-end;
  height: 120px;
  padding:5px 50px;
  background: #2914fb;
  box-shadow: 0px 10px 28px 0 #8f79f9;
  transform: translateY(0) rotate(0);
  overflow: hidden;
}

.input-box input[type='text']{
  width: 70%;
  height: 50px;
  line-height: 70px;
  padding: 15px;
  border:none;
  outline: none;
  background: #8f79f9;
  font-size:28px;
  color: #0a0620;
  transform-origin: left center;
  transform: translateY(0) rotate(0);
  font-family: 'Abel', sans-serif;
}

/* display or output div */
.display-div{
  position: absolute;
  left:0;
  top:100%;
  width: 100%;
  height: 100%;
  background:#f2f2f2;
}

.display-div p{
  opacity:0;
}

.display-div.comeup{
  display: flex;
  justify-content: center;
  align-items: center;
}

.display-div.comeup p{
  opacity: 1;
  color:#000000;
  font-family: 'Lobster', cursive;
  padding: 30px;
}


/* add icon */
.add-icon{
  position: absolute;
  left:50px;
  top:50%;
  width:50px;
  height: 50px;
  transform: translateY(-50%);
  cursor: pointer;
}

.add-icon > div{
  position: realtive;
}

.add-icon > div:after,
.add-icon > div:before{
  content:'';
  position: absolute;
  background:#ffffff;
}

/* horizontal line */
.add-icon > div:after{
  width: 100%;
  height:1px;
  top:50%;
  left:0;
  transform: translateY(-50%);
}

/* vertical line */
.add-icon > div:before{
  width: 1px;
  height:100%;
  top:0;
  left:50%;
  transform: translateX(-50%);
}


/* animations */

.comeup{
  animation: comeup 0.2s forwards 0.9s ease-out;
}

.go{
  animation: go 0.5s forwards 0.5s ease-out;
}

.shake{
  animation: shake 0.5s forwards 0.6s ease-out;
}


@keyframes comeup{
  80%{
    top:40%;
  }

  100%{
    top:0;

  }
}

@keyframes go{
  80%{
    transform: translateY(-90%) rotate(-90deg);
  }
  100%{
    transform-origin: left center;
    transform: translateY(-100%) rotate(-90deg);
  }
}



@keyframes shake{
  60%{
    transform: translateY(0) rotate(-5deg);
  }
  100%{
    transform-origin: left center;
    transform: translateY(0) rotate(0deg);
  }
}
